import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

// package imports
import { Auth } from "aws-amplify";

// package imports
import LockIcon from "@mui/icons-material/Lock";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import TokenIcon from "@mui/icons-material/Token";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import FeedbackButton from "./FeedbackButton";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";

// redux imports
import { useSelector, useDispatch } from "react-redux";
import { signOutUser } from "../features/user/userSlice";
import { resetResponses } from "../features/responses/responsesSlice";
import { resetTokens } from "../features/tokens/tokensSlice";
import { clearApiKey } from "../features/apiKey/apiKeySlice";

// function imports
import { exportCSVFile } from "../functions/convertToCSV";

const GenerateBottomSelections = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    responses: { value: responses },
    tokens: { value: tokens, maxTokens },
  } = useSelector((store) => store);

  const [clearResponses, setClearResponses] = useState(false);

  const handleClearResponses = () => {
    if (clearResponses) dispatch(resetResponses());
    setClearResponses((prevState) => !prevState);
  };

  return (
    <div className="mt-auto">
      <div className="flex items-center p-2 rounded-md transition-all">
        <div className="flex items-center justify-center text-gray-secondary mr-4">
          <TokenIcon sx={{ fontSize: 22 }} />
        </div>
        <span className="text-white">
          {tokens} {tokens === 1 ? "Idea" : "Ideas"} Generated{" "}
          {tokens > 100 ? "Out Of " + maxTokens : ""}
        </span>
      </div>
      <Link to="/bookmarks">
        <div
          id="bookmarksLink"
          className="flex items-center hover:bg-navy-secondary p-2 rounded-md transition-all cursor-pointer"
        >
          <div className="flex items-center justify-center text-gray-secondary mr-4">
            <BookmarksIcon sx={{ fontSize: 22 }} />
          </div>
          <span className="text-white">View Bookmarks</span>
        </div>
      </Link>
      <FeedbackButton/>
      <div
        id="exportResponsesLink"
        className="flex items-center hover:bg-navy-secondary p-2 rounded-md transition-all cursor-pointer"
        onClick={() => exportCSVFile(responses)}
      >
        <div className="flex items-center justify-center text-gray-secondary mr-4">
          <SimCardDownloadIcon sx={{ fontSize: 22 }} />
        </div>
        <span className="text-white">Export Responses</span>
      </div>
      <div
        id="clearResponsesLink"
        className="flex items-center hover:bg-navy-secondary p-2 rounded-md transition-all cursor-pointer"
        onClick={handleClearResponses}
      >
        <div className="flex items-center justify-center text-gray-secondary mr-4">
          {!clearResponses ? (
            <DeleteIcon sx={{ fontSize: 22 }} />
          ) : (
            <CheckIcon sx={{ fontSize: 22 }} />
          )}
        </div>
        <span className="text-white">
          {!clearResponses ? "Clear Responses" : "Confirm Clear Responses"}
        </span>
      </div>
      <div
        className="flex items-center hover:bg-navy-secondary p-2 rounded-md transition-all cursor-pointer"
        onClick={() => {
          dispatch(clearApiKey());
          dispatch(resetResponses());
          dispatch(resetTokens());
          dispatch(signOutUser());
          Auth.signOut();
          navigate("/auth/sign-in");
        }}
      >
        <div className="flex items-center justify-center text-gray-secondary mr-4">
          <LockIcon sx={{ fontSize: 22 }} />
        </div>
        <span className="text-white">Sign Out</span>
      </div>
    </div>
  );
};

export default GenerateBottomSelections;
