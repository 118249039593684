import * as React from "react";
import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ForumIcon from "@mui/icons-material/Forum";
// import { capitalize } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 0,
};

export default function FeedbackButton() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <div
        id="feedbackLink"
        className="flex items-center hover:bg-navy-secondary p-2 rounded-md transition-all cursor-pointer"
        onClick={handleOpen}
      >
        <div className="flex items-center justify-center text-gray-secondary mr-4">
          <ForumIcon sx={{ fontSize: 22 }} />
        </div>
        <div
          style={{
            color: "white",
            padding: "0px",
            height: "24px",
            fontFamily: "Source Sans Pro, sans-serif",
            fontSize: "16px",
          }}
        >
          Feedback
        </div>
      </div>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <iframe
              title="Feedback Form"
              style={{ width: "780px", height: "680px" }}
              src="https://mit.co1.qualtrics.com/jfe/form/SV_6JMEbEGrH6wjOHs"
            />
          </Box>
        </Modal>
      </div>
    </div>
  );
}
