import {
  LandingUsageProblemFormulation,
  LandingUsageThinkAboutProblem,
  LandingUsageFindSolutions,
} from "../assets";

const landingPageInstructions = [
  {
    title: "Input a problem statement",
    description: "Keep it short (10-30 words), clear, and specific. You'll have the chance to revisit and revise your statement as you go.",
    image: LandingUsageProblemFormulation,
  },
  {
    title: "Ideator helps you explore the problem",
    description:
      "It will generate ideas that help you reflect on your problem. These can help you better understand your goals and refine your statement.",
    image: LandingUsageThinkAboutProblem,
  },
  {
    title: "Ideator helps you explore solutions",
    description:
      "It will share several ideas, especially those that harness collective intelligence and Superminds. Combine and refine the most promising ones.",
    image: LandingUsageFindSolutions,
  },
];

export default landingPageInstructions;
