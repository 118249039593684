import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";

// package imports
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

// component imports
import GeneratePanel from "../components/GeneratePanel";
import Responses from "../components/Responses";
import { HowToButton } from "../components/HowToButton";

// redux imports
import { useSelector, useDispatch } from "react-redux";
import { setUserIsCCIResearcher } from "../features/user/userSlice";
import { signOutUser } from "../features/user/userSlice";
import { resetResponses } from "../features/responses/responsesSlice";
import { resetTokens } from "../features/tokens/tokensSlice";
import { clearApiKey } from "../features/apiKey/apiKeySlice";

let responseCount = 0;

export function SetResponseCount(n) {
  responseCount = n;
}

export function GetResponseCount() {
  return responseCount;
}

const Main = () => {
  const [generatePanelOpened, setGeneratePanelOpened] = useState(true);
  const user = useSelector((store) => store.user.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // loading determines whether the tool is generating responses or not. Passed as
  // props to GeneratePanel (controls the state for when the user clicks on generating
  // or one of the presets) and Responses (displays loader)
  const [loading, setLoading] = useState(false);

  const responsesDivRef = useRef(null);

  /**
   * useEffect on mount to clear previous versions localstorage openai-api-key & responses
   * also directs users to sign-in if user is not set.
   */
  useEffect(() => {
    if (user) {
      if (user.signInUserSession.accessToken.payload.iat < 1684940850){
        // check if user logged in before Wed, May 24, 2023 11:00:00 AM EST and logs them out if true to clear old responses
        dispatch(clearApiKey());
        dispatch(resetResponses());
        dispatch(resetTokens());
        dispatch(signOutUser());
        Auth.signOut();
        navigate("/auth/sign-in");
      }
    }
    if (user && "custom:group" in user.attributes) {
      let user_group = JSON.parse(user.attributes["custom:group"])[0];
      let cci_api_groups = ["cci", "cci-guest", "malone", "accenture", "waitlist", "study", "leadership", "orbit", "startmit", "idealab", "delft"]
      if (cci_api_groups.includes(user_group)){
        dispatch(setUserIsCCIResearcher(true));
        // console.log("use cci api");
      }
      else dispatch(setUserIsCCIResearcher(false));
    } else navigate("/auth/sign-in");
    localStorage.removeItem("openai-api-key");
    localStorage.removeItem("responses");
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    window.addEventListener("unload", handleTabClosing);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
      window.removeEventListener("unload", handleTabClosing);
    };
  });

  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/ideate" && GetResponseCount() === 0) {
      var howToButton = document.getElementById("howToButton");
      howToButton.click();
    }
  }, [location.pathname]);

  const handleTabClosing = () => {
    alert("");
  };

  const alertUser = (event) => {
    event.preventDefault();
    event.returnValue = "";
  };

  return (
    <div className="bg-navy-primary w-full flex relative">
      <GeneratePanel
        generatePanelOpened={generatePanelOpened}
        setGeneratePanelOpened={setGeneratePanelOpened}
        loading={loading}
        setLoading={setLoading}
        responsesDivRef={responsesDivRef}
        id="generatePanel"
      />
      <HowToButton
        id="howToButton"
      />
      <Responses loading={loading} responsesDivRef={responsesDivRef} />
      {!generatePanelOpened && (
        <div
          className="absolute top-2 bg-navy-primary rounded-full w-12 h-12 flex items-center justify-end cursor-pointer transition-all translate-x-[-1.5rem] hover:translate-x-[-1.25rem]"
          onClick={() => setGeneratePanelOpened(true)}
        >
          <div className="flex items-center justify-center text-white">
            <ChevronRightIcon />
          </div>
        </div>
      )}
    </div>
  );
};

export default Main;
