import { createSlice } from "@reduxjs/toolkit";

export const responsesSlice = createSlice({
  name: "responses",
  initialState: {
    value: [],
  },
  reducers: {
    createResponseGroup: (state, action) => {
      action.payload["ideationNumber"] = state.value.length;
      state.value.push(action.payload);
    },
    addResponse: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value.slice(-1)[0].responses.push(action.payload);
    },
    /**
     * addChildrenResponse adds the childrenResponse to the right parent response
     * by navigating through the responseGroupIndex (the index representing the
     * outer response groups) and responseIndex (the index representing the parent
     * response within a response group). If the parent response being updated is
     * not the very last response group, we move the response group associated with
     * the parent response being updated to be the last response group (the idea
     * is that when user use "Run Again" on a response from some time ago, it will
     * bring the response group to the very top since any newly generated response
     * groups will be brought to the very top) It's the last group because the response
     * groups are rendered in reversed order.
     *
     * @function addChildrenResponse
     * @param {object} state
     * @param {object} action
     */
    addChildrenResponse: (state, action) => {
      // logic for updated the children responses for a parent response in a response group
      const { responseGroupIndex, responseIndex, childrenResponse } =
        action.payload;
      state.value[responseGroupIndex].dateTime = new Date();
      state.value[responseGroupIndex].responses[
        responseIndex
      ].childrenResponses.push(childrenResponse);
      // the logic for moving response group if the one being updated isn't last
      if (responseGroupIndex !== state.value.length - 1) {
        var tempResponses = [...state.value];
        var tempResponseGroupToMove = tempResponses[responseGroupIndex];
        tempResponses.splice(responseGroupIndex, 1);
        tempResponses.push(tempResponseGroupToMove);
        state.value = tempResponses;
      }
    },
    resetResponses: (state, action) => {
      state.value = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addResponse,
  createResponseGroup,
  addChildrenResponse,
  resetResponses,
} = responsesSlice.actions;

export default responsesSlice.reducer;
