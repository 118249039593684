import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import responsesSlice from "./features/responses/responsesSlice";
import userSlice from "./features/user/userSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";
import tokensSlice from "./features/tokens/tokensSlice";
import apiKeySlice from "./features/apiKey/apiKeySlice";

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const reducer = combineReducers({
  responses: responsesSlice,
  user: userSlice,
  tokens: tokensSlice,
  apiKey: apiKeySlice,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export default configureStore({
  reducer: persistedReducer,
  middleware: customizedMiddleware,
});
