import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import EmailIcon from "@mui/icons-material/Email";
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import { LandingBanner, CCILogo } from "../../assets";
import { InvertButton } from "../../components/Button";
import landingPageInstructions from "../../constants/landing_page_instructions";

const UsageCard = ({ title, description, image, step }) => {
  return (
    <div className="p-8 bg-slate-200 rounded-2xl h-[500px] w-[375px]">
      <div className="bg-slate-100 w-full h-1/2 p-container rounded-2xl flex justify-center items-center mb-4">
        <img src={image} alt="" className="object-contain h-full" />
      </div>
      <div className="grid place-items-center">
        <div className="w-12 h-12 grid place-items-center rounded-full bg-slate-100 mb-4">
          <span className="font-bold text-lg">{step}</span>
        </div>
      </div>
      <div>
        <h1 className="font-semibold text-lg mb-2 text-center">{title}</h1>
        <p className="text-center">{description}</p>
      </div>
    </div>
  );
};

const Landing = () => {
  const [emailCopied, setEmailCopied] = useState(false);

  const handleCopyEmailToClipboard = async () => {
    setEmailCopied(true);
    navigator.clipboard.writeText("ideator@mit.edu");
    await new Promise((r) => setTimeout(r, 3000));
    setEmailCopied(false);
  };

  return (
    <div className="w-full min-h-screen h-full bg-slate-50 flex justify-center items-center flex-col">
      <div className="w-full flex justify-center">
        <section className="w-3/4">
          <Header />
        </section>
      </div>
      <div style={{
        borderWidth: "2px",
        borderStyle: "solid",
        borderImage: "linear-gradient(to right, darkblue, darkorchid) 1"
      }}>
        <p className="text-lg" align="justify" style={{paddingLeft: "10px", paddingRight: "10px"}}>
          <b>Update</b>: Our latest research is now <a className="ml-1 underline cursor-pointer select-none text-lg text-blue-600" href="https://dl.acm.org/doi/10.1145/3643562.3672611" target="_blank" rel="noreferrer">published online</a>!
          <br/>
          "Supermind Ideator: How Scaffolding Human-AI Collaboration Can Increase Creativity"
        </p>
      </div>
      <br/>
      {/* Landing Info Section */}
      <div className="w-3/4 h-full pb-10">
        <section
          className="w-full flex flex-col items-center md:flex-row"
          style={{
            minHeight: "fit-content",
          }}
        >
          <div className="w-2/4">
            <h1 className="text-4xl font-bold mb-4">
              Welcome to Supermind Ideator
            </h1>
            <p className="text-lg pb-5" align="justify">
            The Ideator is an AI-assisted tool that can help you think about and generate solutions to many kinds of problems.
            </p>
            <p className="text-lg" align="justify">
            Problems like: I want to…
            </p>
            <List className="text-lg" sx={{ listStyleType: 'disc', pl: 4, py: 0 }}>
            <ListItem sx={{ display: 'list-item', p: 0 }}>think innovatively about a new product launch</ListItem>
            <ListItem sx={{ display: 'list-item', p: 0 }}>redeploy miners whose jobs are going away as their mines close</ListItem>
            <ListItem sx={{ display: 'list-item', p: 0 }}>find creative ways to choose an employee benefit plan</ListItem>
            <ListItem sx={{ display: 'list-item', p: 0 }}>identify ways to reduce freshwater consumption in my community</ListItem>
            </List>
            <p className="text-lg pt-5" align="justify">
            Use it to jumpstart your creativity, standalone or as a complement to methods you like, individually or in groups with multiple participants.
            </p>
          </div>
          <div className="w-2/4 pl-10">
            <img
              src={LandingBanner}
              style={{}}
              alt="Supermind Idea Generator Logo"
            />
          </div>
        </section>
      </div>
      {/* How To Section */}
      <div className="w-full bg-slate-100 flex justify-center py-5">
        <section className="w-4/4">
          <div className="flex flex-wrap gap-16 justify-center">
            {landingPageInstructions.map(
              ({ title, description, image }, index) => {
                const step = index + 1;
                return (
                  <UsageCard
                    title={title}
                    description={description}
                    image={image}
                    step={step}
                    key={index}
                  />
                );
              }
            )}
          </div>
        </section>
      </div>
      {/* Additional Text Section */}
      <div className="w-full flex justify-center py-12">
        <section className="w-3/4">
          <p className="text-lg mb-8">
            Remember: creativity can result from the collective intelligence of
            humans and computers. Ideator will help you take advantage of that.
            But it is not an expert system, and may return answers that are
            wrong, silly, or useless. Its role: help you explore possibilities
            you might have not considered, by inviting you to think and suspend judgment.
            We recommend you use it after you have generated some ideas of your own,
            as a complement to your imagination: YOU are the ultimate ideator.
          </p>
          <div className="flex items-center justify-center">
            <div className="grid place-items-center mr-2">
              <EmailIcon sx={{ fontSize: 20 }} />
            </div>
            {emailCopied ? (
              <p>Email copied to clipboard!</p>
            ) : (
              <>
                <p className="text-lg">Tell us what you think at</p>
                <span
                  className="ml-1 underline cursor-pointer select-none text-lg text-blue-600"
                  onClick={handleCopyEmailToClipboard}
                >
                  ideator@mit.edu
                </span>
              </>
            )}
          </div>
          <div className="flex items-center justify-center">
            <br></br><br></br>
            <a href="https://accessibility.mit.edu/" target="_blank" rel="noreferrer">
              <span
                className="ml-1 underline cursor-pointer select-none text-lg text-blue-600"
              >
                Accessibility Info
              </span>
            </a>
          </div>
        </section>
      </div>
      {/* Button Actions Section */}
      <Grid container justifyContent="center" spacing={2}>
        <Grid item>
          <a href="https://ideator.mit.edu/waitlist" target="_blank" rel="noreferrer">
            <InvertButton
              text="Join Ideator Waitlist"
              buttonWidthTailwind="w-60"
              backward
            />
          </a>
        </Grid>
        <Grid item>
          <Link to="/ideate">
            <InvertButton text="Get Ideating!" buttonWidthTailwind="w-60" />
          </Link>
        </Grid>
        <Grid item>
          <Link to="/about">
            <InvertButton
              text="Learn More About Ideator"
              buttonWidthTailwind="w-60"
              backward
            />
          </Link>
        </Grid>
      </Grid>
      {/* Developed By Section */}
      <div className="w-full flex justify-center py-12">
        <section className="w-3/4 flex flex-col items-center">
          <h1 className="mb-4 text-lg font-semibold">Developed by:</h1>
          <a href='https://cci.mit.edu/' target='blank' ><img src={CCILogo} alt="cci_logo" className="h-16 object-contain" /></a>
        </section>
      </div>
    </div>
  );
};

export default Landing;
