import React from "react";

// component imports
import Authentication from "../../components/Authentication";

const SignIn = () => {
  return (
    <div className="w-full h-screen flex justify-center items-center bg-slate-100">
      <section className="flex-1 bg-navy-primary h-full w-full"></section>
      <Authentication initialFormType="Sign In" />
    </div>
  );
};

export default SignIn;
