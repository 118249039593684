import SupermindIdeaGeneratorLogo from "./ideator_logo.png";
import LandingUsageProblemFormulation from "./landing-problem-statement.png";
import LandingUsageThinkAboutProblem from "./landing-think-about-problem.png";
import LandingUsageFindSolutions from "./landing-find-solutions.png";
import LandingBanner from "./ideator_landing.png";
import CCILogo from "./cci_logo.png";

export {
  SupermindIdeaGeneratorLogo,
  LandingUsageProblemFormulation,
  LandingUsageThinkAboutProblem,
  LandingUsageFindSolutions,
  LandingBanner,
  CCILogo,
};
