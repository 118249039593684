import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import QuestionMark from "@mui/icons-material/QuestionMark";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { Typography } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-30%, -50%)",
  width: 600,
  height: 300,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

export function HowToButton() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <div
        style={{
          position: "fixed",
          bottom: "8px",
          right: "8px",
          zIndex: 99,
          borderRadius: "20px",
        }}
        id="howToButton"
        className="items-center bg-navy-primary hover:bg-slate-200 p-0 m-0 rounded-lg transition-all cursor-pointer"
        onClick={handleOpen}
      >
        <div className="items-center justify-center p-0 m-0">
          <QuestionMark
            sx={{
              padding: "8px",
              fontSize: 40,
              color: "white",
              "&:hover": {
                color: "black",
              },
            }}
          />
        </div>
      </div>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} style={{ borderRadius: "30px" }}>
            <Typography variant="h5">How To Use Ideator</Typography>
            <List
              className="text-md"
              sx={{ listStyleType: "disc", pl: 4, py: 1 }}
            >
              <ListItem sx={{ p: 0, mb: 1 }}>
                <span>
                  Start by <b>inputing a problem</b> you want to work on.
                  <br></br>For example “think innovatively about a [product]
                  launch”
                </span>
              </ListItem>
              <ListItem sx={{ p: 0, mb: 1 }}>
                <span>
                  Use <b>Explore Problem</b> to create additional and/or better
                  problem statements
                </span>
              </ListItem>
              <ListItem sx={{ p: 0, mb: 1 }}>
                <span>
                  Use <b>Explore Solutions</b> as raw ideas you can filter
                  and/or recombine
                </span>
              </ListItem>
              <ListItem sx={{ p: 0, mb: 1 }}>
                <span>
                  Use <b>More Choices</b> to select moves "a la carte" and
                  customize your ideation
                </span>
              </ListItem>
              {/* <ListItem sx={{ p: 0, mb: 1 }}>
                <span>
                  <b>Diverge first</b> by creating many ideas across multiple
                  dimensions
                </span>
              </ListItem>
              <ListItem sx={{ p: 0, mb: 1 }}>
                <span>
                  <b>Then converge</b> by eliminating, combining and modifying
                  those ideas
                </span>
              </ListItem> */}
              {/* <ListItem sx={{ p: 0, mb: 1 }}>
                <span>
                  <b>Human brains first</b>: read the questions that Ideator
                  shows and consider your own answers, then read its ideas
                </span>
              </ListItem>
              <ListItem sx={{ p: 0, mb: 1 }}>
                <span>
                  <b>Suspend judgment</b>: seemingly irrelevant ideas may give
                  you unusual inspiration for things that might works
                </span>
              </ListItem> */}
              <ListItem sx={{ p: 0, mb: 1 }}>
                <span>
                  <b>Iterate</b>. Do not expect perfect results the first time.
                  Use Ideator to help you think through the process
                </span>
              </ListItem>
            </List>
            <Typography
              variant="body"
              sx={{ position: "absolute", bottom: 0, padding: "10px" }}
            >
              Click the "?" at the bottom right of the screen to bring this information back
              up.
            </Typography>
          </Box>
        </Modal>
      </div>
    </div>
  );
}
