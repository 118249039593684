import React from "react";

// component imports
import InfoHover from "./InfoHover";

const HoverTextH1 = ({
  title,
  hoverText,
  hoverLink,
}) => {
  return (
    <div className="mb-4">
      <div className="flex items-center mb-2">
        <h1
          className="text-base font-semibold mr-2 text-white"
          id="generate-panel-problem-statement"
        >
          {title}
        </h1>
        <InfoHover text={hoverText} link={hoverLink} />
      </div>
    </div>
  );
};

export default HoverTextH1;
