import React from "react";
import { useParams } from "react-router-dom";

// component imports
import Authentication from "../../components/Authentication";

const SignUp = () => {
  const { group } = useParams();

  return (
    <div className="w-full h-screen flex justify-center items-center bg-slate-100">
      <section className="flex-1 bg-navy-primary h-full w-full"></section>
      <Authentication initialFormType="Sign Up" group={group} />
    </div>
  );
};

export default SignUp;
