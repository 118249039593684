import React, { useState } from "react";
import parse from "html-react-parser";
import Header from "./Header";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import frequentlyAskedQuestions from "../../constants/frequently_asked_questions";

const FAQ = ({ question, answer, lastQuestion }) => {
  const [showAnswer, setShowAnswer] = useState(false);
  return (
    <div className={`border-slate-200 ${lastQuestion ? "" : "border-b-[1px]"}`}>
      <div
        className={`flex justify-between items-center cursor-pointer py-6 ${
          showAnswer ? "" : ""
        }`}
        onClick={() => setShowAnswer((prevState) => !prevState)}
      >
        <h1 className="font-semibold">{question}</h1>
        <div className="grid place-items-center">
          {showAnswer ? <RemoveIcon /> : <AddIcon />}
        </div>
      </div>
      {showAnswer && <div className="opacity-80 mb-6">{parse(answer)}</div>}
    </div>
  );
};

const About = () => {
  return (
    <div className="w-full h-auto bg-slate-50 flex flex-col items-center">
      <div className="w-3/4">
        <Header />
      </div>
      <div className="w-full flex-1 flex gap-12">
        {/* Side Panel */}
        <div
          className="w-[300px]"
          style={{ marginLeft: "calc(0.125 * 100vw)" }}
        >
          <ul className="w-full">
            {[["#about","About"], ["#faq","FAQ"]].map((text, index) => {
              const lastTab = index === 3 - 1;
              return (
                <li
                  key={index}
                  className={`py-2 px-4 hover:bg-slate-200 transition-all cursor-pointer border-slate-200 ${
                    lastTab ? "" : "border-b-[1px]"
                  }`}
                >
                  <a href={text[0]}>{text[1]}</a>
                </li>
              );
            })}
          </ul>
        </div>
        {/* Main Display Of Information */}
        <div className="flex-1" style={{ paddingRight: "calc(0.125 * 100vw)" }}>
          <section className="mb-12">
            <h1 id="about" className="font-bold text-2xl mb-4">About</h1>
            <p className="mb-4 opacity-80">
              The objective of Supermind Ideator (Ideator, in short) is to
              inspire new practices and tools that help develop better
              organizations, processes, and products that use superminds.
            </p>
            <p className="mb-4 opacity-80">
              Ideator is the result of the work of the Center for Collective
              Intelligence’s Design Lab, which applies the concepts, methods and
              theories on <a
                href="https://cci.mit.edu/superminds/"
                target="_blank"
                rel="noreferrer"
                className="underline text-blue-600"
              >collective intelligence and superminds</a>.
            </p>
            <p className="mb-4 opacity-80">
              Ideator uses a supermind to do its job: large language models,
              trained on human-generated text and code, are queried in ways that
              lead to more interesting answers - answers that include new
              organizational designs based on supermind.
            </p>
          </section>
          <section className="mb-12">
            <h1 id="faq" className="font-bold text-2xl mb-4">FAQ</h1>
            {frequentlyAskedQuestions.map(({ question, answer }, index) => {
              const lastQuestion =
                index === frequentlyAskedQuestions.length - 1;
              return (
                <FAQ
                  question={question}
                  answer={answer}
                  key={index}
                  lastQuestion={lastQuestion}
                />
              );
            })}
          </section>
        </div>
      </div>
    </div>
  );
};

export default About;
