import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  height: 350,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 0,
};

export default function ConsentInfo() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <span className="text-sm">Your voluntary use grants</span>
      <span
        className="text-sm cursor-pointer select-none text-blue-600"
        onClick={handleOpen}
      >
        {" "}
        consent to participate
      </span>
      <span className="text-sm"> in associated research.</span>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} style={{ borderRadius: "30px" }}>
            <p className="p-3 font-bold text-lg" align="center">
              Ideator Consent Information
            </p>
            <p className="p-3 font text-md">
              Use of the Ideator system is part of research being conducted by
              researchers at Massachusetts Institute of Technology (MIT). Your
              use of the system is voluntary, you may decline to answer any or
              all questions, and you can stop at any time. The system will
              record your input and output (excluding API keys). When results of
              the research are made public, no information will be included that
              would reveal your identity.
            </p>
          </Box>
        </Modal>
      </div>
    </div>
  );
}
