import React from "react";
import axios from "axios";

const APITest = () => {
  const onRun = async () => {
    try {
      console.log("fetching...");

      const data = {
        problem: "plant more trees",
        model: "gpt-4",
        max_tokens: 100,
        api_key: "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",
      };

      const res = await axios.post(
        "https://ideator.mit.edu/api/v1/run/general.what_am_i_missing",
        data
      );

      console.log("finished");

      console.log(res);
    } catch (error) {
      console.log("error");
      console.log(error);
    }
  };

  return (
    <div>
      <button onClick={onRun}>Run</button>
    </div>
  );
};

export default APITest;
