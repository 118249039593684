const frequentlyAskedQuestions = [
  {
    question: "How much does it cost to use Supermind Ideator?",
    answer:
      "<p>Ideator's application is free to use as long as the user either has their own OpenAI API key, or has been granted access by MIT. <a href='https://creativecommons.org/licenses/by/4.0/' target='_blank' className='underline text-blue-600'>Creative Common license terms</a> apply to use. You are free to share and adapt output. You must give appropriate credit, provide a link to this license, and indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor endorses you or your use.</p>",
  },
  {
    question: "How does Ideator work?",
    answer:
      "Ideator is based on a combination of OpenAI’s GPT-3 natural language models and the MIT Supermind Design Library. It uses prompting techniques derived from the <a href='https://cci.mit.edu/wp-content/uploads/2021/08/Supermind-Design-Primer-v2.pdf' target='_blank' className='underline text-blue-600'>Supermind Primer</a> as well as other creativity techniques.",
  },
  {
    question: "Can I trust that the AI is telling me the truth?",
    answer:
      "The underlying OpenAI GPT-3 technology is not connected to the internet, and it can occasionally produce incorrect answers. It has limited knowledge of the world and events after 2021 and may occasionally produce harmful instructions or biased content. In line with OpenAI’s recommendations we'd recommend checking whether responses from the model are accurate or not.",
  },
  {
    question: "Who can view my interactions with Ideator?",
    answer:
      "As part of a general commitment to safe and responsible AI, the development team may review interactions to improve our systems and to ensure the content complies with our policies and safety requirements. We will not share your information and data with any third party.",
  },
  {
    question: "Will you use my interactions for training?",
    answer:
      "Your interactions may be used, in aggregate with others, to improve the quality of our algorithms. We will not share your interactions data with third parties, and we don’t intend to review individual output except as required to fix or refine the overall models.",
  },
  {
    question: "Can you delete my data?",
    answer:
      "Yes, please notify us via email if you want to delete your account and data.",
  },
  {
    question:
      "How can I save ideas that I’ve created?",
    answer:
      "You can bookmark ideas by selecting the Bookmark icon (this is next to the rating icons). Output will be cleared on sign-out, however bookmarks remain available after signing back in.",
  },
  {
    question: "Why do I need my own OpenAI key? What happens when I use that?",
    answer:
      "Your OpenAI key, obtainable at <a href='https://openai.com/api/' target='_blank' className='underline text-blue-600'>OpenAI’s website</a>, enables you to use all the AI models and data that OpenAI allows. Ideator keeps your key in your own browser cache and does not store that information in any remote database.",
  },
  {
    question:
      "Is Ideator’s code and prompts available anywhere, for instance on GitHub?",
    answer:
      "Not at this time. We are planning to make APIs available. For now, we suggest that you embed this website as-is into any solution that you are building. If you build your own tool, in line with the CC license above, please refer to your own work as a derivative of Ideator, as appropriate. Thank you!",
  },
];

export default frequentlyAskedQuestions;
