import React from "react";

const DropdownSelect = ({ param, options, selected, updateSelected }) => {
  return (
    <div className="w-full">
      <select
        name=""
        id=""
        value={selected}
        onChange={(e) => updateSelected(param, e.target.value)}
        className="w-full rounded-md focus:outline-none p-sm bg-navy-secondary cursor-pointer text-gray-secondary"
      >
        <option value="None">None</option>
        {options.map((option, index) => {
          return (
            <option value={option} key={index}>
              {option}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default DropdownSelect;
