import { useState, useEffect } from "react";

// package imports
import { API } from "aws-amplify";

// graphql imports
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";

const useResponseUserSelections = ({ responseId, type }) => {
  const [bookmarked, setBookmarked] = useState(false);
  const [preference, setPreference] = useState("neutral");

  useEffect(() => {
    setInitialResponseUserSelections();
    //eslint-disable-next-line
  }, []);

  const setInitialResponseUserSelections = async () => {
    const data = await fetchResponse();
    if (data) {
      const { bookmarked: fetchedBookmarked, preference: fetchedPreference } =
        data;
      setBookmarked(fetchedBookmarked);
      setPreference(fetchedPreference);
    }
  };

  /**
   * fetchResponse fetches the current response from the database
   */
  const fetchResponse = async () => {
    const { data } = await API.graphql({
      query:
        type === "parent" ? queries.getResponse : queries.getChildrenResponse,
      variables: { id: responseId },
    });
    return type === "parent" ? data.getResponse : data.getChildrenResponse;
  };

  /**
   * updateResponse updates the response in the database with a new value for the specified parameter
   * @param {string} field the parameter name that is getting updated
   * @param {string} value the new updated value
   */
  const updateResponse = async (field, value) => {
    const updatedResponse = await API.graphql({
      query:
        type === "parent"
          ? mutations.updateResponse
          : mutations.updateChildrenResponse,
      variables: {
        input: {
          id: responseId,
          [field]: value,
        },
      },
    });
    // console.log(updatedResponse);
  };

  const updatePreference = (newPreference) => {
    setPreference(newPreference);
    updateResponse("preference", newPreference);
  };

  const onLike = () => {
    if (preference === "liked") updatePreference("neutral");
    else updatePreference("liked");
  };

  const onDislike = () => {
    if (preference === "disliked") updatePreference("neutral");
    else updatePreference("disliked");
  };

  const onBookmark = () => {
    setBookmarked((prevState) => !prevState);
    updateResponse("bookmarked", !bookmarked);
  };

  const liked = preference === "liked";
  const disliked = preference === "disliked";

  return { liked, disliked, bookmarked, onLike, onDislike, onBookmark };
};

export default useResponseUserSelections;
