import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    value: null,
    isCCIResearcher: null,
  },
  reducers: {
    signInUser: (state, action) => {
      state.value = action.payload;
    },
    signOutUser: (state, action) => {
      state.value = null;
      state.isCCIResearcher = null;
    },
    setUserIsCCIResearcher: (state, action) => {
      state.isCCIResearcher = action.payload;
    },
  },
});

export const { signInUser, signOutUser, setUserIsCCIResearcher } =
  userSlice.actions;

export default userSlice.reducer;
