const temperatureMapping = {
  val_to_int: {
    Low: 0.7,
    Medium: 1.0,
    High: 1.3,
  },
  int_to_val: {
    0.7: "Low",
    1.0: "Medium",
    1.3: "High",
  },
};

export default temperatureMapping;
