import React from "react";
import { Link } from "react-router-dom";

// package imports
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

// component imports
import GeneratePanelMoreChoices from "./GeneratePanelMoreChoices";
import GenerateTextInput from "./GenerateTextInput";
import HoverTextH1 from "./HoverTextH1";
import GenerateBottomSelections from "./GenerateBottomSelections";
import Button from "./Button";
import HomeIcon from "@mui/icons-material/Home";

// hook imports
import useGeneratePanel from "../hooks/useGeneratePanel";

import { useSelector } from "react-redux";

const GeneratePanel = (props) => {
  const {
    user: { isCCIResearcher },
  } = useSelector((store) => store);
  const {
    generatePanelOpened,
    setGeneratePanelOpened,
    loading,
    setLoading,
    responsesDivRef,
  } = props;
  const {
    parameters,
    updateParameter,
    resetParameters,
    onSubmit,
    onPresetSubmit,
  } = useGeneratePanel({ setLoading, responsesDivRef });

  return (
    <div
      className={`w-[350px] transition-all select-none ${
        generatePanelOpened ? "" : "hidden"
      }`}
    >
      {/* Problem Statement Input */}
      <div
        className="p-container flex flex-col"
        // style={{ minHeight: "100vh" }}
      >
        <div className="flex justify-between items-center mb-4">
          <div
            className="flex items-center justify-center text-gray-secondary cursor-pointer"
            onClick={() => setGeneratePanelOpened(false)}
          >
            <ChevronLeftIcon />
          </div>
          <Link to="/">
            <div
              style={{ display: "flex", alignItems: "center" }}
              className="text-white font-bold text-xl"
            >
              <HomeIcon />
              <h1>Supermind Ideator</h1>
            </div>
          </Link>
        </div>
        <div className="overflow-visible">
          {!isCCIResearcher && (
            <GenerateTextInput
              title="OpenAI API Key"
              placeholder="Enter API Key"
              type="openaiApiKey"
              value={parameters.openaiApiKey}
              hoverText="You need an OpenAI key to use Ideator. Click the 'i' to go to their website and find yours."
              hoverLink="https://beta.openai.com/account/api-keys"
              updateValue={updateParameter}
            />
          )}
          <GenerateTextInput
            title="I want to..."
            placeholder=""
            type="problem"
            value={parameters.problem}
            hoverText="What problem do you want to solve? Try to be specific and concrete. For example: 'find the best retirement plan for my employees'"
            updateValue={updateParameter}
            id="problemStatementTextInput"
          />
          <div className="mb-2">
            <HoverTextH1
              title="Help me..."
              hoverText="'Explore Problem' helps your explore your problem statement with several moves.
                         'Explore Solutions' generates a range of ideas according to a few Supermind Design moves.
                         'More Choices' lets you pick individual moves."
            />
            <div className="mb-2 flex flex-col gap-2">
              <Button
                text="Explore Problem"
                buttonColor="purple"
                loadingType="disable"
                loading={loading}
                onClick={() => onPresetSubmit("Explore Problem")}
                id="exploreProblemButton"
              />
              <Button
                text="Explore Solutions"
                buttonColor="cyan"
                loadingType="disable"
                loading={loading}
                onClick={() => onPresetSubmit("Explore Solutions")}
                id="exploreSolutionButton"
              />
            </div>
            {/* Ideation Method ("More Choices") */}
          </div>
          <div>
            <h1 className="text-base font-semibold text-white mb-2">
              Advanced
            </h1>
            <GeneratePanelMoreChoices
              parameters={parameters}
              updateParameter={updateParameter}
              resetParameters={resetParameters}
              loading={loading}
              onSubmit={onSubmit}
            />
          </div>
        </div>
        <GenerateBottomSelections />
      </div>
    </div>
  );
};

export default GeneratePanel;
