import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Modal from "react-modal";

import { Auth, Hub } from "aws-amplify";

import {
  Ideator,
  SignIn,
  SignUp,
  Landing,
  About,
  Bookmarks,
  APITest,
  Prolific,
} from "./pages";
import { useDispatch } from "react-redux";
import { signOutUser } from "./features/user/userSlice";

import "react-toastify/dist/ReactToastify.css";

Modal.setAppElement("#root");

function App() {
  const dispatch = useDispatch();
  const checkCurrentAuthenticatedUser = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      // dispatch(signInUser(user));
      // console.log(user);
    } catch (error) {
      dispatch(signOutUser());
      console.log(error);
    }
  };

  useEffect(() => {
    checkCurrentAuthenticatedUser();
  });

  const listener = (data) => {
    switch (data.payload.event) {
      case "signIn":
        console.log("user signed in!");
        break;
      case "signOut":
        console.log("user signed out!");
        break;
      default:
        break;
    }
  };

  Hub.listen("auth", listener);

  return (
    // <div className="h-auto min-h-screen flex flex-col">
    <Router>
      {/* <Header /> */}
      <Routes>
        <Route index element={<Landing />} />
        <Route path="/about" element={<About />} />
        <Route path="/api-test" element={<APITest />} />
        <Route path="/ideate" element={<Ideator />} />
        <Route path="/bookmarks" element={<Bookmarks />} />
        <Route path="/auth/sign-in" element={<SignIn />} />
        <Route path="/auth/sign-up" element={<SignUp />} />
        <Route path="/auth/prolific" element={<Prolific />} />
        <Route path="/auth/prolific/:group" element={<Prolific />} />
        <Route path="/auth/sign-up/:group" element={<SignUp />} />
      </Routes>
    </Router>
    // </div>
  );
}

export default App;
