import React from "react";

// component import
import InfoHover from "./InfoHover";

const TileSelect = ({ selections, selected, updateSelected }) => {
  return (
    <div className="flex">
      {selections.map(
        ({ selectionName: selection, hoverText, hoverLink }, index) => {
          const last = index === selections.length - 1;
          const first = index === 0;
          const selectionSelected = selection === selected;
          return (
            <div
              className={`flex flex-1 justify-center p-xs bg-navy-secondary cursor-pointer ${
                first && "rounded-l-md"
              } ${last && "rounded-r-md"}`}
              key={index}
              onClick={() => updateSelected(selection)}
            >
              <div
                className={`flex items-center flex-1 justify-center p-xs ${
                  selectionSelected ? "bg-navy-tertiary rounded-[4px]" : ""
                }`}
              >
                <span className={`mr-2 text-gray-secondary`}>{selection}</span>
                {hoverText && <InfoHover text={hoverText} link={hoverLink} />}
              </div>
            </div>
          );
        }
      )}
    </div>
  );
};

export default TileSelect;
