/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createResponse = /* GraphQL */ `
  mutation CreateResponse(
    $input: CreateResponseInput!
    $condition: ModelResponseConditionInput
  ) {
    createResponse(input: $input, condition: $condition) {
      id
      problem
      model
      move
      ideaFrame
      basic
      groupify
      cognify
      technify
      solutionType
      explorationType
      semanticSimilarity
      temperature
      response
      bookmarked
      preference
      runType
      childrenResponses {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateResponse = /* GraphQL */ `
  mutation UpdateResponse(
    $input: UpdateResponseInput!
    $condition: ModelResponseConditionInput
  ) {
    updateResponse(input: $input, condition: $condition) {
      id
      problem
      model
      move
      ideaFrame
      basic
      groupify
      cognify
      technify
      solutionType
      explorationType
      semanticSimilarity
      temperature
      response
      bookmarked
      preference
      runType
      childrenResponses {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteResponse = /* GraphQL */ `
  mutation DeleteResponse(
    $input: DeleteResponseInput!
    $condition: ModelResponseConditionInput
  ) {
    deleteResponse(input: $input, condition: $condition) {
      id
      problem
      model
      move
      ideaFrame
      basic
      groupify
      cognify
      technify
      solutionType
      explorationType
      semanticSimilarity
      temperature
      response
      bookmarked
      preference
      runType
      childrenResponses {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createChildrenResponse = /* GraphQL */ `
  mutation CreateChildrenResponse(
    $input: CreateChildrenResponseInput!
    $condition: ModelChildrenResponseConditionInput
  ) {
    createChildrenResponse(input: $input, condition: $condition) {
      id
      problem
      model
      move
      ideaFrame
      basic
      groupify
      cognify
      technify
      solutionType
      explorationType
      semanticSimilarity
      temperature
      response
      bookmarked
      runType
      preference
      createdAt
      updatedAt
      responseChildrenResponsesId
      owner
    }
  }
`;
export const updateChildrenResponse = /* GraphQL */ `
  mutation UpdateChildrenResponse(
    $input: UpdateChildrenResponseInput!
    $condition: ModelChildrenResponseConditionInput
  ) {
    updateChildrenResponse(input: $input, condition: $condition) {
      id
      problem
      model
      move
      ideaFrame
      basic
      groupify
      cognify
      technify
      solutionType
      explorationType
      semanticSimilarity
      temperature
      response
      bookmarked
      runType
      preference
      createdAt
      updatedAt
      responseChildrenResponsesId
      owner
    }
  }
`;
export const deleteChildrenResponse = /* GraphQL */ `
  mutation DeleteChildrenResponse(
    $input: DeleteChildrenResponseInput!
    $condition: ModelChildrenResponseConditionInput
  ) {
    deleteChildrenResponse(input: $input, condition: $condition) {
      id
      problem
      model
      move
      ideaFrame
      basic
      groupify
      cognify
      technify
      solutionType
      explorationType
      semanticSimilarity
      temperature
      response
      bookmarked
      runType
      preference
      createdAt
      updatedAt
      responseChildrenResponsesId
      owner
    }
  }
`;
export const createTokens = /* GraphQL */ `
  mutation CreateTokens(
    $input: CreateTokensInput!
    $condition: ModelTokensConditionInput
  ) {
    createTokens(input: $input, condition: $condition) {
      owner
      tokens
      maxTokens
      createdAt
      updatedAt
    }
  }
`;
export const updateTokens = /* GraphQL */ `
  mutation UpdateTokens(
    $input: UpdateTokensInput!
    $condition: ModelTokensConditionInput
  ) {
    updateTokens(input: $input, condition: $condition) {
      owner
      tokens
      maxTokens
      createdAt
      updatedAt
    }
  }
`;
export const deleteTokens = /* GraphQL */ `
  mutation DeleteTokens(
    $input: DeleteTokensInput!
    $condition: ModelTokensConditionInput
  ) {
    deleteTokens(input: $input, condition: $condition) {
      owner
      tokens
      maxTokens
      createdAt
      updatedAt
    }
  }
`;
