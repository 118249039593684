import React from "react";

// component imports
import InfoHover from "./InfoHover";

// package imports
import TextareaAutosize from "react-textarea-autosize";

const GenerateTextInput = ({
  title,
  placeholder,
  value,
  updateValue,
  type,
  hoverText,
  hoverLink,
}) => {
  return (
    <div className="mb-4">
      <div className="flex items-center mb-2">
        <h1
          className="text-base font-semibold mr-2 text-white"
          id="generate-panel-problem-statement"
        >
          {title} <span className="font-bold text-red-600">*</span>
        </h1>
        <InfoHover text={hoverText} link={hoverLink} />
      </div>

      <div className="flex">
        {/* {type === "problem" && (
          <span className="mr-1 pb-[1px] text-white block">I want to</span>
        )} */}
        {type !== "openaiApiKey" ? (
          <TextareaAutosize
            placeholder={placeholder}
            value={value}
            onChange={(e) => updateValue(type, e.target.value)}
            className="flex-1 p-2 rounded-md resize-none text-gray-secondary bg-navy-secondary text-base focus:outline-none"
          />
        ) : (
          <input
            placeholder={placeholder}
            onChange={(e) => updateValue(type, e.target.value)}
            value={value}
            type="password"
            className="flex-1 p-2 rounded-md resize-none text-gray-secondary bg-navy-secondary text-base focus:outline-none"
          />
        )}
      </div>
    </div>
  );
};

export default GenerateTextInput;
