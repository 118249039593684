import React from "react";

// package imports
import HashLoader from "react-spinners/HashLoader";

// constant imports
import { responseGroupLoadingColor } from "../constants/response_group_color";

const Loading = ({ responseGroupType, loadingComponentRef }) => {
  return (
    <div
      className="flex items-center justify-center bg-white p-container rounded-br-lg rounded-bl-lg"
      ref={loadingComponentRef}
    >
      <p className="mr-2 font-semibold">Generating ideas</p>
      <div className="flex items-center justify-center">
        <HashLoader
          size={20}
          color={responseGroupLoadingColor[responseGroupType]}
        />
      </div>
    </div>
  );
};

export default Loading;
