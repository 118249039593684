var technifyTechnologies = [
  "Advanced data analytics", // this
  "Next-generation wireless networks, including 5G and 6G",
  "Machine learning: neural networks and text recognition and analysis", // this
  "Protective cyber security technologies",
  "Edge computing",
  "Cloud computing", // this
  "Sensory perception and recognition",
  "Safe and/or secure AI",
  "Augmented reality",
  "Virtual reality",
  "Satellite-based communications",
  "Digital twin",
  "IoT connectivity",
  "Computer Vision", // this
  "Natural-language processing", // this
  "Speech technology",
  "Blockchain", // this
  "Digital identity infrastructure",
  "Knowledge graph technologies",
  "Synthethic data",
  "Mixed reality",
  "Robotic Process Automation (RPA)", // this
  "Quantum networking",
];

technifyTechnologies = technifyTechnologies.sort();

export default technifyTechnologies;
