import React from "react";

// component imports
import InfoHover from "./InfoHover";
import TileSelect from "./TileSelect";

// constant imports
import temperature_mapping from "../constants/qualitative_temperature_mapping";

const GenerateTemperatureInput = ({ updateTemperature, temperature }) => {
  const updateSelected = (val) => {
    updateTemperature("temperature", temperature_mapping.val_to_int[val]);
  };

  return (
    <div className="mb-4">
      <div className="flex items-center mb-2">
        <h1 className="mr-2 text-white">Creativity</h1>
        <InfoHover text="Higher creativity will try to provide more surprising answers at the cost of more often missing the mark." />
      </div>
      <TileSelect
        selections={[
          { selectionName: "Low" },
          { selectionName: "Medium" },
          { selectionName: "High" },
        ]}
        updateSelected={updateSelected}
        selected={temperature_mapping.int_to_val[temperature]}
      />
    </div>
  );
};

export default GenerateTemperatureInput;
