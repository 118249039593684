import React from "react";

// package imports
import BarLoader from "react-spinners/BarLoader";

const buttonColorMap = {
  blue: "bg-navy-secondary hover:bg-blue-500",
  red: "bg-navy-secondary hover:bg-red-500",
  rose: "bg-navy-secondary hover:bg-rose-500",
  green: "bg-navy-secondary hover:bg-green-500",
  stone: "bg-navy-secondary hover:bg-stone-500",
  indigo: "bg-navy-secondary hover:bg-indigo-500",
  amber: "bg-navy-secondary hover:bg-amber-500",
  emerald: "bg-navy-secondary hover:bg-emerald-500",
  cyan: "bg-navy-secondary hover:bg-cyan-500",
  teal: "bg-navy-secondary hover:bg-teal-500",
  purple: "bg-navy-secondary hover:bg-purple-500",
};

const Button = ({
  text,
  onClick,
  loading,
  buttonColor,
  loadingType = "animation",
  id,
}) => {
  const buttonThemeColor = buttonColor
    ? buttonColorMap[buttonColor]
    : buttonColorMap["blue"];

  const loadingTypeIsAnimation = loadingType === "animation";

  return (
    <button
      id={id}
      onClick={onClick}
      className={`w-full p-sm h-[40px] flex justify-center items-center focus:outline-2 rounded-md text-gray-secondary transition-all ${
        loading ? "pointer-events-none opacity-25" : ""
      } ${buttonThemeColor}`}
    >
      {loading && loadingTypeIsAnimation ? <BarLoader color="#36d7b7" /> : text}
    </button>
  );
};

const InvertButton = ({ text, backward, buttonWidthTailwind }) => {
  return (
    <button
      className={`py-4 ${
        buttonWidthTailwind ? buttonWidthTailwind : "px-16"
      } rounded-lg font-bold text-lg transition-all border-2 border-navy-primary ${
        backward
          ? "bg-white text-navy-primary hover:bg-navy-primary hover:text-white"
          : "bg-navy-primary text-white hover:bg-white hover:text-navy-primary"
      }`}
    >
      {text}
    </button>
  );
};

export default Button;
export { InvertButton };
