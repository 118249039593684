import React from "react";

// component imports
import Button from "./Button";
import ConsentInfo from "./ConsentInfo";

// hook imports
import useAuthentication from "../hooks/useAuthentication";

const Authentication = ({ initialFormType, group }) => {
  const {
    buttonText,
    linkText,
    linkRedirect,
    form,
    loading,
    error,
    formType,
    setFormType,
    updateForm,
    onSubmit,
    resendConfirmationCode,
    clearPassword,
  } = useAuthentication({
    initialFormType,
    group,
  });

  return (
    <section className="flex-1 flex justify-center items-center">
      <form className="w-[350px] p-container">
        <div className="mb-8">
          <h1 className="font-bold text-lg">{formType}</h1>
          {["Prolific"].includes(formType) && !group && (
            <div>Error - No Username Provided</div>
          )}
          {["Prolific"].includes(formType) && group && (
            <div>Logging you into Ideator...</div>
          )}
          {["Sign In", "Sign Up"].includes(formType) && (
            <div className="text-sm mb-2">
              <ConsentInfo></ConsentInfo>
            </div>
          )}
          {["Sign In"].includes(formType) && (
            <p className="text-sm mb-2">
              Need an account? Join the{" "}
              <a
                className="text-sm cursor-pointer select-none text-blue-600"
                href="https://ideator.mit.edu/waitlist"
                target="_blank"
                rel="noreferrer"
              >
                waitlist
              </a>
              .
            </p>
          )}
          {["Sign Up"].includes(formType) && (
            <p className="text-sm">
              If you already have an account{" "}
              <a
                className="cursor-pointer select-none text-blue-600"
                href="/auth/sign-in"
              >
                Sign-In
              </a>{" "}
              instead.
            </p>
          )}
        </div>
        {["Confirm Code", "Change Password"].includes(formType) && (
          <div className="mb-4">
            <p>Code is sent to your inbox!</p>
          </div>
        )}
        <div className="mb-4">
          <p className="text-red-600 font-semibold">{error}</p>
        </div>
        {formType === "Sign Up" && (
          <div className="flex gap-2">
            <div className="flex flex-col mb-4">
              <input
                type="text"
                value={form.firstName}
                name="firstName"
                placeholder="First Name"
                className="w-full rounded-md p-2 bg-gray-50 focus:outline-2 focus:outline-blue-400/50"
                onChange={updateForm}
              />
            </div>
            <div className="flex flex-col mb-4">
              <input
                type="text"
                value={form.lastName}
                name="lastName"
                placeholder="Last Name"
                className="w-full rounded-md p-2 bg-gray-50 focus:outline-2 focus:outline-blue-400/50"
                onChange={updateForm}
              />
            </div>
          </div>
        )}
        {["Confirm Code", "Change Password"].includes(formType) && (
          <div className="flex flex-col mb-4">
            <input
              type="text"
              value={form.confirmationCode}
              name="confirmationCode"
              placeholder="Confirmation Code"
              className="w-full rounded-md p-2 bg-gray-50 focus:outline-2 focus:outline-blue-400/50"
              onChange={updateForm}
            />
          </div>
        )}
        {["Sign Up", "Sign In", "Forgot Password"].includes(formType) && (
          <div className="flex flex-col mb-4">
            <input
              type="text"
              value={form.email}
              name="email"
              placeholder="Email"
              className="w-full rounded-md p-2 bg-gray-50 focus:outline-2 focus:outline-blue-400/50"
              onChange={updateForm}
            />
          </div>
        )}
        {["Sign Up", "Sign In", "Change Password"].includes(formType) && (
          <div className="flex flex-col mb-4">
            <input
              type="password"
              value={form.password}
              name="password"
              placeholder="Password"
              className="w-full rounded-md p-2 bg-gray-50 focus:outline-2 focus:outline-blue-400/50"
              onChange={updateForm}
            />
          </div>
        )}
        {["Sign Up", "Change Password"].includes(formType) && (
          <div className="flex flex-col mb-4">
            <input
              type="password"
              value={form.confirmPassword}
              name="confirmPassword"
              placeholder="Confirm Password"
              className="w-full rounded-md p-2 bg-gray-50 focus:outline-2 focus:outline-blue-400/50"
              onChange={updateForm}
            />
          </div>
        )}
        {["Sign Up"].includes(formType) && (
          <div className="mb-4">
            <p className="text-sm mb-2">
              We want to make the Supermind Ideator better!
              <br />
              If you're willing to have our team contact you for feedback,
              please check the box below.
            </p>
            <div className="flex items-center">
              <input
                type="checkbox"
                name="permissionToBeContacted"
                className="mr-3"
                onChange={updateForm}
                checked={form.permissionToBeContacted}
              />
              <p className="text-sm">
                I agree to be contacted for feedback purposes.
              </p>
            </div>
          </div>
        )}
        {["Sign In", "Forgot Password"].includes(formType) && (
          <div className="flex flex-col items-center">
            <span
              className="cursor-pointer text-blue-400 hover:underline"
              onClick={() => {
                setFormType(linkRedirect[formType]);
                clearPassword();
              }}
            >
              {linkText[formType]}
            </span>
          </div>
        )}
        {formType === "Confirm Code" && (
          <div className="flex justify-center mb-4">
            <span
              className="text-blue-400 cursor-pointer hover:underline"
              onClick={resendConfirmationCode}
            >
              Resend confirmation code
            </span>
          </div>
        )}
        <div className="mt-8 mb-4">
          <Button
            text={buttonText[formType]}
            onClick={onSubmit}
            loading={loading}
          />
        </div>
        {/* Login and Register shortcut redirect */}
        {/* <div>
          <p className="text-center">
            {formType === "Sign In"
              ? "Don't have an account? "
              : formType === "Sign Up"
              ? "Already have an account? "
              : ""}
            {formType === "Sign In" && (
              <Link to="/auth/sign-up">
                <span className="cursor-pointer text-blue-400 hover:underline">
                  Register
                </span>
              </Link>
            )}
            {formType === "Sign Up" && (
              <Link to="/auth/sign-in">
                <span className="cursor-pointer text-blue-400 hover:underline">
                  Login
                </span>
              </Link>
            )}
          </p>
        </div> */}
      </form>
    </section>
  );
};

export default Authentication;
