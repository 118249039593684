import React from "react";
import { useParams } from "react-router-dom";

// component imports
import Authentication from "../../components/Authentication";

const Prolific = () => {
  const { group } = useParams();

  return (
    <div className="w-full h-screen flex justify-center items-center bg-slate-100">
      <section className="flex-1 bg-navy-primary h-full w-full"></section>
      <Authentication initialFormType="Prolific" group={group} />
    </div>
  );
};

export default Prolific;
