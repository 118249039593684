import SignIn from "./Authentication/SignIn";
import SignUp from "./Authentication/SignUp";
import Prolific from "./Authentication/Prolific";
import Landing from "./Home/Landing";
import Ideator from "./Main";
import About from "./Home/About";
import Bookmarks from "./Bookmarks";
import APITest from "./APITest";

export { SignIn, SignUp, Landing, About, Ideator, Bookmarks, APITest, Prolific };
