import React from "react";

// redux imports
import { useSelector } from "react-redux";

// component imports
import Response from "./Response";
import Label from "./Label";
import Loading from "./Loading";

// package imports
import { format } from "date-format-parse";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { SetResponseCount } from "../pages/Main";

// constant imports
import responseGroupColor from "../constants/response_group_color";

// function imports
import { removeIWantToDupIfAny } from "../functions/utils";

const ResponseGroup = (props) => {
  const {
    loading,
    responsesGroup,
    responseGroupType,
    dateTime,
    problem,
    index,
    ideationNumber,
  } = props;

  const responses = useSelector((store) => store.responses.value);
  const newestResponseGroup = index === 0;
  const [expanded, setExpanded] = React.useState(
    newestResponseGroup ? true : false
  );

  React.useLayoutEffect(() => {
    if (index == 0){
      setExpanded(true);
    }
  }, [index]);

  return (
    <div key={index}>
      <div
      // dropping ${newestResponseGroup ? "" : ""} 
        className={`flex p-sm bg-white ${
          expanded ? "rounded-tl-lg rounded-tr-lg mb-0.5" : "mb-2 rounded-lg"
        }`}
      >
        <div
          className="flex-1 flex items-center cursor-pointer select-none py-1"
          onClick={() => setExpanded((prevState) => !prevState)}
        >
          <div className="flex justify-center items-center mr-2">
            {expanded ? (
              <RemoveIcon sx={{ fontSize: 16 }} />
            ) : (
              <AddIcon sx={{ fontSize: 16 }} />
            )}
          </div>
          <div className="flex flex-1 pr-1 justify-between items-center">
            <div className="flex items-center">
              <Label
                backgroundColor={responseGroupColor[responseGroupType]}
                textColor="text-black"
                tailwindStyle="mr-2"
                text={responseGroupType}
              />
              <Label
                backgroundColor="bg-gray-200/25"
                textColor="text-black/75"
                tailwindStyle="mr-2"
                text={`Idea ${ideationNumber + 1}`}
              />
              <span>{removeIWantToDupIfAny(problem)}</span>
            </div>
            <span className="text-black/75 text-sm">
              Last modified:{" "}
              {format(new Date(dateTime), "ddd, MMM D, YYYY h:mm A")}
            </span>
          </div>
        </div>
      </div>
      {expanded && (
        <div key={index} className="mb-8">
          {responsesGroup.map((response, innerIndex) => {
            const responseGroupIndex = responses.length - 1 - index;
            const lastResponseInResponseGroup =
              innerIndex === responsesGroup.length - 1;
            return (
              <Response
                {...response}
                key={innerIndex}
                responseGroupIndex={responseGroupIndex}
                responseIndex={innerIndex}
                newestResponseGroup={newestResponseGroup}
                lastResponseInResponseGroup={lastResponseInResponseGroup}
                responseGroupType={responseGroupType}
                ideationNumber={ideationNumber}
                generating={loading}
              />
            );
          })}
          {loading && newestResponseGroup && (
            <Loading responseGroupType={responseGroupType} />
          )}
        </div>
      )}
    </div>
  );
};

const Responses = ({ loading, responsesDivRef }) => {
  const responses = useSelector((store) => store.responses.value);
  SetResponseCount(responses.length);

  return (
    <div
      className="flex-1 p-container overflow-auto bg-slate-100"
      style={{ minHeight: "100vh" }}
      ref={responsesDivRef}
    >
      {responses &&
        responses
          .slice(0)
          .reverse()
          .map(
            (
              {
                responses: responsesGroup,
                dateTime,
                problem,
                type: responseGroupType,
                ideationNumber,
              },
              index
            ) => {
              return (
                <ResponseGroup
                  loading={loading}
                  responsesGroup={responsesGroup}
                  dateTime={dateTime}
                  problem={problem}
                  index={index}
                  responseGroupType={responseGroupType}
                  key={index}
                  ideationNumber={ideationNumber}
                />
              );
            }
          )}
    </div>
  );
};

export default Responses;
