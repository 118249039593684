import { createSlice } from "@reduxjs/toolkit";

export const tokensSlice = createSlice({
  name: "tokens",
  initialState: {
    value: null,
    maxTokens: null,
  },
  reducers: {
    decrementTokens: (state, action) => {
      state.value -= action.payload;
    },
    incrementTokens: (state, action) => {
      state.value += action.payload;
    },
    setTokensAmount: (state, action) => {
      state.value = action.payload;
    },
    setMaxTokens: (state, action) => {
      state.maxTokens = action.payload;
    },
    setTokensInfinite: (state, action) => {
      state.value = Infinity;
    },
    resetTokens: (state, action) => {
      state.value = null;
      state.maxTokens = null;
    },
  },
});

export const {
  decrementTokens,
  incrementTokens,
  setTokensAmount,
  setTokensInfinite,
  resetTokens,
  setMaxTokens,
} = tokensSlice.actions;

export default tokensSlice.reducer;
