import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API } from "aws-amplify";
import * as queries from "../graphql/queries";
import { getModelBlurb } from "../functions/utils";
import MoonLoader from "react-spinners/MoonLoader";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Label from "../components/Label";
import { parseTechnify } from "../functions/utils";
import temperatureMapping from "../constants/qualitative_temperature_mapping";
import { labelMapping } from "../constants/supermind_models";

const BookmarksResponse = (props) => {
  const {
    problem,
    response,
    move,
    model,
    basic,
    groupify,
    cognify,
    technify,
    solutionType,
    explorationType,
    temperature,
    preference,
  } = props;

  return (
    <div className="bg-white p-container mb-4 rounded-lg shadow-md">
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center gap-2">
          <h1 className="font-semibold">I want to {problem}</h1>
          {/* TODO - change label to read from API based on move */}
          {move && model === "gpt-3.5-turbo" && (
            <Label
              backgroundColor="bg-gray-200/25"
              text={`${labelMapping[move]}`}
              textColor="text-black/75"
              tailwindStyle=""
            />
          )}
          {basic && basic !== "None" && (
            <Label
              backgroundColor="bg-gray-200/25"
              text={`${basic}`}
              textColor="text-black/75"
              tailwindStyle=""
            />
          )}
          {groupify && groupify !== "None" && (
            <Label
              backgroundColor="bg-gray-200/25"
              text={`Groupify: ${groupify}`}
              textColor="text-black/75"
              tailwindStyle=""
            />
          )}
          {cognify && cognify !== "None" && (
            <Label
              backgroundColor="bg-gray-200/25"
              text={`Cognify: ${cognify}`}
              textColor="text-black/75"
              tailwindStyle=""
            />
          )}
          {technify && technify !== "[]" && (
            <Label
              backgroundColor="bg-gray-200/25"
              text={`Technify: ${parseTechnify(JSON.parse(technify))}`}
              textColor="text-black/75"
              tailwindStyle=""
            />
          )}
          {solutionType && (
            <Label
              backgroundColor="bg-gray-200/25"
              text={solutionType}
              textColor="text-black/75"
              tailwindStyle=""
            />
          )}
          {explorationType && (
            <Label
              backgroundColor="bg-gray-200/25"
              text={explorationType}
              textColor="text-black/75"
              tailwindStyle=""
            />
          )}
          {temperature && (
            <Label
              backgroundColor="bg-gray-200/25"
              text={`Creativity: ${temperatureMapping.int_to_val[temperature]}`}
              textColor="text-black/75"
              tailwindStyle=""
            />
          )}
        </div>
        {preference === "liked" ? (
          <ThumbUpIcon sx={{ fontSize: 22, color: "#4ade80" }} />
        ) : preference === "disliked" ? (
          <ThumbDownIcon sx={{ fontSize: 22, color: "#f87171" }} />
        ) : null}
      </div>
      {move && (
        <p className="mb-1">
          {getModelBlurb({
            model:move,
            parameters: { basic, groupify, cognify, technify },
          })}
        </p>
      )}
      {model && model !== "gpt-3.5-turbo" && (
        <p className="mb-1">
          {getModelBlurb({
            model,
            parameters: { basic, groupify, cognify, technify },
          })}
        </p>
      )}
      <p className="whitespace-pre-wrap">{response}</p>
    </div>
  );
};

const Bookmarks = () => {
  const [bookmarkedResponses, setBookmarkedResponses] = useState([]);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    fetchAllBookmarkedResponses();
  }, []);

  const fetchAllBookmarkedResponses = async () => {
    var newNextToken = null;
    var out = [];
    setFetching((prevState) => !prevState);
    try {
      while (true) {
        const {
          data: {
            listResponses: { items, nextToken },
          },
        } = await API.graphql({
          query: queries.listResponses,
          variables: {
            filter: {
              bookmarked: {
                eq: true,
              },
            },
            ...(newNextToken !== null && { nextToken: newNextToken }),
          },
        });
        if (items.length > 0) out.push(...items);
        if (nextToken) newNextToken = nextToken;
        else break;
      }
      setBookmarkedResponses(out);
      // console.log(out);
    } catch (error) {
      console.log(error);
    }
    setFetching((prevState) => !prevState);
  };

  return (
    <div className="bg-slate-100 min-h-screen p-container">
      <div className="flex items-center gap-2 mb-4">
        <Link to="/ideate">
          <div className="flex items-center cursor-pointer">
            <ArrowBackIcon sx={{ fontSize: 22, marginRight: "4px" }} />
            <span>Ideate</span>
          </div>
        </Link>
        <h1 className="font-bold text-lg">Bookmarks</h1>
      </div>
      {fetching ? (
        <div className="flex items-center justify-center">
          <MoonLoader size={60} color="#000000" />
        </div>
      ) : (
        <>
          {bookmarkedResponses.map((bookmarkedResponse, index) => {
            return <BookmarksResponse {...bookmarkedResponse} key={index} />;
          })}
        </>
      )}
    </div>
  );
};

export default Bookmarks;
