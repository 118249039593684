import React, { useState } from "react";

// package imports
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";

// constant imports
import technifyTechnologies from "../constants/supermind_technify_technologies";

const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(156, 163, 175, 0.5)",
  },
  content: {
    width: "600px",
    height: "65vh",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "0.375rem",
    filter:
      "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))",
  },
};

const TechnifyTab = ({ selected, technology, updateValue }) => {
  const italic =
    technology === "None" || technology === "3 Random Technologies";
  return (
    <div
      className={`p-sm mb-1 cursor-pointer ${
        selected ? "bg-zinc-400" : "bg-gray-100"
      }`}
      onClick={() => updateValue("technify", technology)}
    >
      <span className={`${italic && "italic"} ${selected && "text-white"}`}>
        {technology}
      </span>
    </div>
  );
};

const TechnifySelection = ({ updateValue, technify }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const technifyText =
    technify.size === 0
      ? "None"
      : technify.size === 1 && technify.has("3 Random Technologies")
      ? "Random"
      : technify.size === 1
      ? "Choice Selected"
      : "Choices Selected";

  return (
    <>
      <div
        className="p-sm w-full bg-navy-secondary rounded-md h-[39px] pl-3 cursor-pointer"
        onClick={() => setModalIsOpen((prevState) => !prevState)}
      >
        <span className="text-white">{technifyText}</span>
      </div>
      <Modal
        isOpen={modalIsOpen}
        style={modalStyle}
        onRequestClose={() => setModalIsOpen((prevState) => !prevState)}
      >
        <div className="flex items-center justify-between mb-8">
          <h1 className="text-base font-medium">Technify Menu</h1>
          <div
            onClick={() => setModalIsOpen((prevState) => !prevState)}
            className="grid place-items-center cursor-pointer"
          >
            <CloseIcon />
          </div>
        </div>
        <div>
          <TechnifyTab
            technology="None"
            selected={technify.size === 0}
            updateValue={updateValue}
          />
          <TechnifyTab
            technology="3 Random Technologies"
            selected={
              technify.size === 1 && technify.has("3 Random Technologies")
            }
            updateValue={updateValue}
          />
          {technifyTechnologies.map((technology, index) => {
            const selected = technify.has(technology);
            return (
              <TechnifyTab
                technology={technology}
                updateValue={updateValue}
                selected={selected}
                key={index}
              />
            );
          })}
        </div>
      </Modal>
    </>
  );
};

export default TechnifySelection;
