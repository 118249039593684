import React from "react";
import { SupermindIdeaGeneratorLogo } from "../../assets";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const { pathname } = useLocation();
  return (
    <header className="w-full h-32 flex items-center bg-slate-50 sticky top-0 z-50">
      <div className="flex-1 flex items-center justify-between">
        <Link to="/">
          <div className="flex items-center">
            <img
              src={SupermindIdeaGeneratorLogo}
              alt="Supermind Idea Generator Logo"
              className="w-12 h-12 object-contain mr-4"
            />
          </div>
        </Link>
        <div>
          <ul className="flex">
            <a href="https://ideator.mit.edu/waitlist" target="_blank">
              <li className="ml-8 text-lg cursor-pointer hover:bg-slate-200 py-2 px-4 transition-all rounded-md">
                Join Waitlist
              </li>
            </a>
            <Link to="/ideate">
              <li className="ml-8 text-lg cursor-pointer hover:bg-slate-200 py-2 px-4 transition-all rounded-md">
                Log In
              </li>
            </Link>
            <Link to={ pathname === '/' ? '/about' : '/' }>
              <li className="ml-8 text-lg cursor-pointer hover:bg-slate-200 py-2 px-4 transition-all rounded-md">
              { pathname === '/' ? 'About' : 'Home' }
              </li>
            </Link>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
