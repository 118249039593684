import temperatureMapping from "../constants/qualitative_temperature_mapping";
import { labelMapping } from "../constants/supermind_models";
import { format } from "date-format-parse";

const headers = [
  "problem",
  "response",
  "move",
  "basic",
  "groupify",
  "cognify",
  "technify",
  "temperature",
  "model",
  "preference",
  "bookmarked",
];

function serializeTechnify(technify) {
  var out = "";
  for (let i = 0; i < technify.length; i++) {
    out += `${i + 1}. ${technify[i]}`;
    if (i !== technify.length - 1) out += "; ";
  }
  return out;
}

function addResponseAttributes(out, response) {
  for (let k = 0; k < headers.length; k++) {
    const key = headers[k];
    if (key in response) {
      const value = response[key];
      if (key === "move") {
        if (value in labelMapping){
          out[key].push(labelMapping[value]);
        }
        else{
          out[key].push(value);
        }
      }
      else if (key === "technify") {
        var temp = serializeTechnify(value);
        if (temp.includes(",")) out[key].push('"' + temp + '"');
        else out[key].push(temp);
      } else if (typeof value === "string") {
        if (key === "temperature")
          out[key].push(temperatureMapping.int_to_val[value]);
        else if (key === "problem")
          out[key].push(
            '"' + value.replace(/\n/g, " ").replace(/"/g, "'") + '"'
          );
        else if (key === "response")
          out[key].push(
            '"' + value.replace(/\n/g, " ").replace(/"/g, "'") + '"'
          );
        else out[key].push(value.replace(/\n/g, " ").replace(/"/g, "'"));
      } else if (key === "bookmarked") out[key].push(value ? "yes" : "no");
    } else out[key].push("n/a");
  }
}

function serializeResponses(responses) {
  var out = {};
  for (let i = 0; i < headers.length; i++) out[headers[i]] = [];
  for (let i = 0; i < responses.length; i++) {
    const responseGroup = responses[i].responses;
    for (let j = 0; j < responseGroup.length; j++) {
      const response = responseGroup[j];
      addResponseAttributes(out, response);
      if (response.childrenResponses.length > 0)
        for (let c = 0; c < response.childrenResponses.length; c++)
          addResponseAttributes(out, response.childrenResponses[c]);
    }
  }
  return out;
}

function convertToCSV(responses) {
  const responsesSerialized = serializeResponses(responses);
  const numOfResponses = responsesSerialized.problem.length;
  var out =
    "Problem,Response,Move";
  for (let i = 0; i < numOfResponses; i++) {
    out += "\n";
    for (let j = 0; j < headers.length-8; j++) {
      out += responsesSerialized[headers[j]][i] + ",";
    }
  }
  return out;
}

function exportCSVFile(responses) {
  const csvResponses = convertToCSV(responses);
  // console.log({ csvResponses });
  let csvContent = "data:text/csv;charset=utf-8," + csvResponses;
  var encodedUri = encodeURI(csvContent);
  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute(
    "download",
    `supermind_ideation_${format(
      new Date(),
      "MMM_D_YYYY_h_m_a"
    ).toLowerCase()}.csv`
  );
  document.body.appendChild(link); // Required for FF

  link.click(); // This will download the data file named "my_data.csv".
}

// exportCSVFile(headers, itemsFormatted, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download

export { exportCSVFile };
