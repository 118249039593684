import React, { useState } from "react";

// component imports
import Button from "./Button";
import GenerateDesignMoveInput from "./GenerateDesignMoveInput";
import GenerateTemperatureInput from "./GenerateTemperatureInput";

const GeneratePanelMoreChoices = ({
  parameters,
  updateParameter,
  resetParameters,
  loading,
  onSubmit,
}) => {
  const [moreChoicesExpanded, setMoreChoicesExpanded] = useState(false);

  return (
    <div
      id="moreChoices"
      className={`${loading ? "pointer-events-none opacity-25" : ""}`}
    >
      <div className="mb-4">
        <div
          className={`relative h-[40px] ${
            moreChoicesExpanded
              ? "bg-navy-tertiary"
              : "bg-navy-secondary hover:bg-navy-tertiary"
          } transition-all rounded-md flex items-center justify-center cursor-pointer`}
          onClick={() => setMoreChoicesExpanded((prevState) => !prevState)}
        >
          <span className="text-gray-secondary">
            {moreChoicesExpanded ? "Less" : "More"} Choices
          </span>
        </div>
      </div>
      {moreChoicesExpanded && (
        <>
          <GenerateDesignMoveInput
            updateValue={updateParameter}
            value={{
              basic: parameters.basic,
              supermind: {
                groupify: parameters.groupify,
                cognify: parameters.cognify,
                technify: parameters.technify,
              },
              
            }}
            id="moreChoicesMoveSelector"
          />
          <GenerateTemperatureInput
            updateTemperature={updateParameter}
            temperature={parameters.temperature}
            id="moreChoicesCreativitySelector"
          />
          <div className="mb-4">
            <p
              className="underline cursor-pointer select-none mb-2 text-white"
              onClick={resetParameters}
            >
              Reset Parameters
            </p>
            {/* {tokens && tokens !== Infinity && <p>{tokens} Tokens Remaining</p>} */}
          </div>
          <div className="mb-4">
            <Button
              text="Generate"
              loading={loading}
              onClick={onSubmit}
              buttonColor="indigo"
              loadingType="disable"
              id="moreChoicesGenerateButton"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default GeneratePanelMoreChoices;
