import React from "react";

const Label = ({ backgroundColor, textColor, tailwindStyle, text }) => {
  return (
    <span
      className={`px-3 py-1 rounded-md font-semibold text-sm ${backgroundColor} ${textColor} ${
        tailwindStyle ? tailwindStyle : ""
      }`}
    >
      {text}
    </span>
  );
};

export default Label;
