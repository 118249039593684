/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getResponse = /* GraphQL */ `
  query GetResponse($id: ID!) {
    getResponse(id: $id) {
      id
      problem
      model
      move
      ideaFrame
      basic
      groupify
      cognify
      technify
      solutionType
      explorationType
      semanticSimilarity
      temperature
      response
      bookmarked
      preference
      runType
      childrenResponses {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listResponses = /* GraphQL */ `
  query ListResponses(
    $filter: ModelResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResponses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        problem
        model
        move
        ideaFrame
        basic
        groupify
        cognify
        technify
        solutionType
        explorationType
        semanticSimilarity
        temperature
        response
        bookmarked
        preference
        runType
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getChildrenResponse = /* GraphQL */ `
  query GetChildrenResponse($id: ID!) {
    getChildrenResponse(id: $id) {
      id
      problem
      model
      move
      ideaFrame
      basic
      groupify
      cognify
      technify
      solutionType
      explorationType
      semanticSimilarity
      temperature
      response
      bookmarked
      runType
      preference
      createdAt
      updatedAt
      responseChildrenResponsesId
      owner
    }
  }
`;
export const listChildrenResponses = /* GraphQL */ `
  query ListChildrenResponses(
    $filter: ModelChildrenResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChildrenResponses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        problem
        model
        move
        ideaFrame
        basic
        groupify
        cognify
        technify
        solutionType
        explorationType
        semanticSimilarity
        temperature
        response
        bookmarked
        runType
        preference
        createdAt
        updatedAt
        responseChildrenResponsesId
        owner
      }
      nextToken
    }
  }
`;
export const getTokens = /* GraphQL */ `
  query GetTokens($owner: ID!) {
    getTokens(owner: $owner) {
      owner
      tokens
      maxTokens
      createdAt
      updatedAt
    }
  }
`;
export const listTokens = /* GraphQL */ `
  query ListTokens(
    $owner: ID
    $filter: ModelTokensFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTokens(
      owner: $owner
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        owner
        tokens
        maxTokens
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
