const responseGroupColor = {
  "Explore Problem": "bg-purple-200/50",
  "Explore Solutions": "bg-cyan-200/50",
  "More Choices": "bg-indigo-200/50",
};

const responseGroupLoadingColor = {
  "Explore Problem": "#c084fc",
  "Explore Solutions": "#22d3ee",
  "More Choices": "#818cf8",
};

export default responseGroupColor;
export { responseGroupLoadingColor };
