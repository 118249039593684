// React imports
import { useState } from "react";

// Package imports
import InfoIcon from "@mui/icons-material/Info";

const InfoHover = ({ text, link }) => {
  const [showText, setShowText] = useState(false);

  return (
    <div
      className="relative"
      onMouseEnter={() => setShowText(true)}
      onMouseLeave={() => setShowText(false)}
    >
      <div
        className={`grid place-items-center text-gray-200${
          link && " cursor-pointer"
        }`}
        onClick={() => {
          if (link) window.open(link, "_blank");
        }}
      >
        <InfoIcon sx={{ color: "#e2e8f0", fontSize: "18px" }} />
      </div>
      {showText && (
        <div className="absolute top-full break-words left-0 mt-2 w-72 bg-gray-50 p-sm rounded-md border border-gray-200 drop-shadow-md z-50">
          <p className="text-sm text-black/75">{text}</p>
        </div>
      )}
    </div>
  );
};

export default InfoHover;
