import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// package imports
import { Auth } from "aws-amplify";
import axios from 'axios';

// react reduc imports
import { useDispatch } from "react-redux";
import { signInUser } from "../features/user/userSlice";

const buttonText = {
  "Sign In": "Login",
  "Sign Up": "Create Account",
  "Forgot Password": "Send Code",
  "Confirm Code": "Confirm",
  "Change Password": "Submit",
};

const linkText = {
  "Sign In": "Forgot your password?",
  "Forgot Password": "Back to sign in",
};

const linkRedirect = {
  "Sign In": "Forgot Password",
  "Forgot Password": "Sign In",
};

const useAuthentication = ({ initialFormType, group }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [form, setForm] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    confirmationCode: "",
    permissionToBeContacted: false,
  });
  const [loading, updateLoading] = useState(false);
  const [error, setError] = useState("");
  const [formType, setFormType] = useState(initialFormType);

  useEffect(() => {
    if (formType === "Prolific"){
      if (group){
        adminSignIn();
      }
    }
  }, [formType, group]);

  const updateForm = (e) => {
    var { name, value } = e.target;
    if (name === "permissionToBeContacted")
      value = !form.permissionToBeContacted;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setError("");
  };

  const clearForm = () =>
    setForm({
      email: "",
      password: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      confirmationCode: "",
    });

  const clearPassword = () =>
    setForm((prevState) => ({
      ...prevState,
      password: "",
      confirmPassword: "",
    }));

  const passwordsMatch = () => form.password === form.confirmPassword;

  const signIn = async () => {
    updateLoading((prevState) => !prevState);
    try {

      const user = await Auth.signIn(form.email, form.password);
      dispatch(signInUser(user));
      // console.log(user);
      navigate("/ideate");
    } catch (error) {
      setError(error.message);
      console.log("error signing in", error);
    }
    updateLoading((prevState) => !prevState);
  };

  const adminSignIn = async () => {
    updateLoading((prevState) => !prevState);
    let _username = group + "@ideator.mit.edu";
    let _password = "prolific";
    try {
      let postData = {
        user: _username,
        pass: _password
      };
      let axiosConfig = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      // const response = await axios.post('https://localhost:3001/prolific_setup', postData, axiosConfig)
      const response = await axios.post('https://cci-smd.mit.edu/clone/prolific_setup', postData, axiosConfig)
      if (response.status === 200){
        const user = await Auth.signIn(_username, _password);
        dispatch(signInUser(user));
        // console.log(user);
        navigate("/ideate");
      }
    } catch (error) {
      setError(error.message);
      console.log("error signing in", error);
    }
    updateLoading((prevState) => !prevState);
  }

  const signUp = async () => {
    if (!passwordsMatch()) {
      setError("Passwords don't match");
      return;
    }
    updateLoading((prevState) => !prevState);
    try {
      const {
        email: username,
        password,
        firstName: given_name,
        lastName: family_name,
        permissionToBeContacted,
      } = form;
      var groupAssignment = ["public"]; // default

      const maloneGroup = group === "malone";
      const accentureGroup = group === "accenture";
      const cciGuestGroup = group === "cci-guest";
      const waitlistGroup = group === "waitlist";
      const studyGroup = group === "study";
      const leadershipGroup = group === "leadership";
      const orbitGroup = group === "orbit";
      const startmitGroup = group === "startmit";
      const idealabGroup = group === "idealab";
      const delftGroup = group === "delft";
      if (maloneGroup) {
        groupAssignment = ["malone"];
      }
      if (accentureGroup) {
        groupAssignment = ["accenture"];
      }
      if (cciGuestGroup) {
        groupAssignment = ["cci-guest"];
      }
      if (waitlistGroup) {
        groupAssignment = ["waitlist"];
      }
      if (studyGroup) {
        groupAssignment = ["study"];
      }
      if (leadershipGroup) {
        groupAssignment = ["leadership"];
      }
      if (orbitGroup) {
        groupAssignment = ["orbit"];
      }
      if (startmitGroup) {
        groupAssignment = ["startmit"];
      }
      if (idealabGroup) {
        groupAssignment = ["idealab"];
      }
      if (delftGroup) {
        groupAssignment = ["delft"];
      }
      await Auth.signUp({
        username,
        password,
        attributes: {
          given_name,
          family_name,
          "custom:group": JSON.stringify(groupAssignment),
          "custom:contact_permission": permissionToBeContacted ? "1" : "0",
        },
      });
      setError("");
      setFormType("Confirm Code");
    } catch (error) {
      setError(error.message);
      console.log("error signing up:", error);
    }
    updateLoading((prevState) => !prevState);
  };

  const confirmSignUp = async () => {
    updateLoading((prevState) => !prevState);
    try {
      const { email: username, confirmationCode: code } = form;
      await Auth.confirmSignUp(username, code);
      navigate("/auth/sign-in");
    } catch (error) {
      setError(error.message);
      console.log("error confirming sign up", error);
    }
    updateLoading((prevState) => !prevState);
  };

  const resendConfirmationCode = async () => {
    document.body.style.cursor = "wait";
    try {
      const { email: username } = form;
      await Auth.resendSignUp(username);
      console.log("code resent successfully");
    } catch (error) {
      setError(error.message);
      console.log("error resending code: ", error);
    }
    document.body.style.cursor = "default";
  };

  const forgotPassword = async () => {
    const { password, confirmPassword } = form;
    if (password !== confirmPassword) {
      setError("Passwords don't match");
      return;
    }
    updateLoading((prevState) => !prevState);
    try {
      const { email: username } = form;
      await Auth.forgotPassword(username);
      setFormType("Change Password");
    } catch (error) {
      setError(error.message);
    }
    updateLoading((prevState) => !prevState);
  };

  const forgotPasswordSubmit = async () => {
    if (!passwordsMatch()) {
      setError("Passwords don't match");
      return;
    }
    updateLoading((prevState) => !prevState);
    try {
      const {
        email: username,
        confirmationCode: code,
        password: new_password,
      } = form;
      await Auth.forgotPasswordSubmit(username, code, new_password);
      setFormType("Sign In");
      clearForm();
    } catch (error) {
      setError(error.message);
    }
    updateLoading((prevState) => !prevState);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (formType === "Sign In") signIn();
    if (formType === "Sign Up") signUp();
    if (formType === "Confirm Code") confirmSignUp();
    if (formType === "Forgot Password") forgotPassword();
    if (formType === "Change Password") forgotPasswordSubmit();
  };

  return {
    buttonText,
    linkText,
    linkRedirect,
    form,
    loading,
    error,
    formType,
    setFormType,
    updateForm,
    onSubmit,
    resendConfirmationCode,
    clearPassword,
  };
};

export default useAuthentication;
