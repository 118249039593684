import React, { useState } from "react";

// component imports
import TileSelect from "./TileSelect";
import DropdownSelect from "./DropdownSelect";
import TechnifySelection from "./TechnifySelection";

// constant imports
import {
  groupifyMoves,
  cognifyMoves,
} from "../constants/supermind_design_moves";

const GenerateDesignMoveInput = ({
  value: {
    basic,
    supermind: { groupify, cognify, technify },
  },
  updateValue,
}) => {
  const [moveSelected, setMoveSelected] = useState("Basic");

  return (
    <div className="mb-4">
      <h1 className="mb-2 text-white">Design Move</h1>
      <TileSelect
        selections={[
          {
            selectionName: "Basic",
            hoverText:
              "These moves change the framing of a problem to open up creative possibilities for solution generation.",
            hoverLink: null,
          },
          {
            selectionName: "Supermind",
            hoverText:
              "Supermind Design moves formulate ideas that leverage collective intelligence. Click to visit the primer that describes each move.",
            hoverLink: "https://cci.mit.edu/wp-content/uploads/2021/08/Supermind-Design-Primer-v2.pdf",
          },
        ]}
        selected={moveSelected}
        updateSelected={setMoveSelected}
        infoHover={true}
      />

      <div className="mt-4">
        {moveSelected === "Basic" && (
          <DropdownSelect
            options={[
              "Zoom In - Parts",
              "Zoom In - Types",
              "Zoom Out - Parts",
              "Zoom Out - Types",
              "Analogize",
              "Reformulation",
              "Supermind Design Library",
              "What Am I Missing",
              "Any",
            ]}
            param={"basic"}
            selected={basic}
            updateSelected={updateValue}
          />
        )}
        {moveSelected === "Supermind" && (
          <>
            {[
              {
                selected: groupify,
                move: "Groupify",
                options: [...groupifyMoves, "Any"],
                param: "groupify",
              },
              {
                selected: cognify,
                move: "Cognify",
                options: [...cognifyMoves, "Any"],
                param: "cognify",
              },
            ].map(({ selected, move, param, options }, index) => {
              return (
                <div key={index} className="flex items-center mb-2">
                  <div className="flex-1">
                    <span className="text-white">{move}</span>
                  </div>
                  <div className="flex-1">
                    <DropdownSelect
                      options={options}
                      param={param}
                      selected={selected}
                      updateSelected={updateValue}
                    />
                  </div>
                </div>
              );
            })}
            <div className="flex items-center mb-2">
              <div className="flex-1">
                <span className="text-white">Technify</span>
              </div>
              <div className="flex-1">
                <TechnifySelection
                  updateValue={updateValue}
                  technify={technify}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GenerateDesignMoveInput;
